
import { IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepageIntroduceForm from '@/components/homepage/introduce-form.vue';

@Component({
  name: 'HomepageIndex',
  components: {
    HomepageIntroduceForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail
}
