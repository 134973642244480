
import { IMyHompageIntroduceSave } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getIntroduceList, saveIntroduceList } from '@/api/myHomepageIntroduce';

@Component({
  name: 'HomepageType1IntroduceForm',
})
export default class extends Vue {
  mounted() {
    getIntroduceList(this.$route.params.nickname).then((res) => {
      this.formDataList = res.data;
      /* eslint-disable */
      this.formDataList.forEach((formData) => {
        if (this.questionList.indexOf(formData.question) < 0) formData.isCustom = true;
      });
    });
    /* eslint-enable */
  }

  private formDataList: IMyHompageIntroduceSave[] = [];

  private questionList = [
    '몇년도에 설립되었나요?',
    '어느 장비가 설치되어 있나요?',
    '보유하고 있는 인증서는 뭐가 있나요?',
  ];

  private handleClickAdd() {
    const formData: IMyHompageIntroduceSave = {
      question: '',
      answer: '',
      isCustom: false,
    };
    this.formDataList.push(formData);
  }

  private handleClickSave() {
    saveIntroduceList(this.formDataList).then(() => {
      alert('저장되었습니다.');
      this.$router.push({ name: 'HomepageIntroduce', params: { nickname: this.$route.params.nickname } });
    });
  }

  private handleClickRemove(index: number) {
    this.formDataList.splice(index, 1);
  }

  private handleChangeQuestion(index: number) {
    if (this.formDataList[index].question === 'write') {
      this.formDataList[index].isCustom = true;
      this.formDataList[index].question = '';
    }
  }
}
